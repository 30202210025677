@import url(https://fonts.googleapis.com/css?family=Raleway:700);

*,
*:before,
*:after {
  box-sizing: border-box;
}
/* html {
            height: 100%;
        } */
body {
  font-family: "Raleway", sans-serif;
  /* height: 100%; */
  padding: 10px;
}

.text-wrapper {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.title {
  font-size: 3em;
  font-weight: 500;
}

.subtitle {
  font-size: 40px;
  font-weight: 700;
  color: #cc3369;
  margin-top: 20px;
}
